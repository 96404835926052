import { Injectable, inject } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot, UrlTree, CanActivateFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { firstValueFrom, Observable, merge, from } from 'rxjs';
import { of } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

import { AppState } from 'app/types';
import { Loggable } from 'app/utils/logging/loggable';

import { AuthService } from '../../services/auth.service';
import { TermsService } from '../../services/terms.service';
import { LaunchDarklyService } from '../services/launch-darkly/launch-darkly.service';
import { AuthenticationState, coreSelectors } from '../state';

/**
 * @deprecated - use canActivateAuthGuard
 *  Class-based Route guards are deprecated in favor of functional guards.
 *  An injectable class can be used as a functional guard using the
 *  inject function: canActivate: [() => inject(myGuard).canActivate()]
 */
@Injectable({
  providedIn: 'root',
})
export class AuthGuardService extends Loggable {
  public constructor(
    public router: Router,
    public authService: AuthService,
    public termsService: TermsService,
    public store: Store<AppState>,
    public ldService: LaunchDarklyService,
  ) {
    super();
    this.setDisplayName('AuthGuardService');
  }

  public canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const signedOut$ = this.store.select(coreSelectors.getAuthenticationState).pipe(
      filter((authState) => authState === AuthenticationState.UNAUTHENTICATED),
      tap((authState) => this.info('Unauthenticated, routing to home', authState)),
      switchMap(() => of(this.navigateToWelcome(state))),
    );

    const signedIn$ = this.store.select(coreSelectors.getAuthenticationState).pipe(
      filter((authState) => authState === AuthenticationState.AUTHENTICATED),
      switchMap(async (): Promise<boolean | UrlTree> => {
        if (this.authService.isAnonymous()) {
          await firstValueFrom(this.authService.signOutUser())
            .catch((error) => this.error('Error signing out user', error))
            .finally(() => this.navigateToWelcome(state));
          return true;
        }

        await this.termsService
          .didAcceptTerms()
          .then((didAccept) => {
            if (!didAccept.valueOf()) {
              return this.navigateToTermsUpdate(state);
            }
            return true;
          })
          .catch((error) => {
            this.error('Error checking user accepted terms', error);
            this.navigateToWelcome(state);
          });
        return true;
      }),
    );

    return merge(signedOut$, signedIn$);
  }

  private navigateToWelcome(state: RouterStateSnapshot) {
    return this.router.createUrlTree(['welcome'], {
      queryParams: {
        return: state.url,
      },
    });
  }

  private navigateToTermsUpdate(state: RouterStateSnapshot) {
    return this.router.createUrlTree(['web/shared/updated-terms'], {
      queryParams: {
        return: state.url,
      },
    });
  }
}

export const canActivateAuthGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Observable<boolean | UrlTree> => {
  const scootaverseGuardService = inject(AuthGuardService);
  const scootaverseSurveyUrl = scootaverseGuardService.router.createUrlTree(
    [`home/scootaverses/activate-survey/${route.params.id}`],
    {},
  );
  const scootaverseUrl = scootaverseGuardService.router.createUrlTree(['home/scootaverses'], {});
  const classicUrl = scootaverseGuardService.router.createUrlTree(['home'], {});

  const $disableScootaverses = new Promise<boolean | UrlTree>((resolve) => {
    const showSurvey = route.url[0].path === 'socialEnded' && route.url[1].path === 'activate-survey';
    if (state.url === scootaverseUrl.toString()) {
      return resolve(true);
    } else if (showSurvey) {
      resolve(scootaverseSurveyUrl);
    }
    return resolve(true);
    // TODO: this is not working as expected
    // return resolve(scootaverseUrl);
  });
  $disableScootaverses
    .then((url) => merge(scootaverseGuardService.canActivate(route, state), of(url)))
    .catch((error) => {
      scootaverseGuardService.error('Error in canActivateScootaverses', error);
      return of(classicUrl);
    });
  return from($disableScootaverses);
};
